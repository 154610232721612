import allSettled from "promise.allsettled";

export const profiles = {
    read(options) {
        return $.getJSON(AdminRoutes.adminProfilesPath(options));
    },

    readForDevice(deviceId, options) {
        return $.getJSON(AdminRoutes.adminDeviceProfilesPath(deviceId, options));
    },

    readForGroup(groupId, options) {
        return $.getJSON(AdminRoutes.adminGroupProfilesPath(groupId, options));
    },

    types(options) {
        return $.getJSON(AdminRoutes.typesAdminProfilesPath(options));
    },

    destroyMultiple(profiles) {
        return allSettled(profiles.map(profile => $.ajax({
            method:   'DELETE',
            dataType: 'json',
            url:      profile.originalRecord
        })));
    },

    readGroupAssignments(profileId, options) {
        return $.getJSON(AdminRoutes.adminProfileGroupAssignmentsPath(profileId), options);
    },

    readGroupsForAssignments(profileId, options) {
        return $.getJSON(AdminRoutes.groupsAdminProfileGroupAssignmentsPath(profileId), options);
    },

    createGroupAssignment(profileId, groupIds) {
        return $.post({
            method: 'POST',
            dataType: 'json',
            contextType: "application/json",
            url: AdminRoutes.adminProfileGroupAssignmentsPath(profileId),
            data: { groupIds }
        })
    },

    removeGroupAssignments(profileId, groupIds) {
        return $.ajax({
            url: AdminRoutes.adminProfileGroupAssignmentsPath(profileId),
            method: 'DELETE',
            dataType: 'json',
            contentType: "application/json",
            data: JSON.stringify({ groupIds }),
        });
    }
};