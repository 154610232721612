import React, { useState, useMemo, useCallback, useEffect } from 'react'

import MuiModal from '../shared/mui_modal';
import {
  FormControl,
  Grid,
  Autocomplete,
  TextField,
  InputLabel,
} from "@mui/material"
import { profiles as profilesClient } from '../../ajax_clients';


export default function AssignGroupsModal({ show, onClose, onSuccess, profileId }) {
  const [groups, setGroups] = useState([])
  const [selectedGroupIds, setSelectedGroupIds] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!show) return

    setLoading(true)

    profilesClient.readGroupsForAssignments(profileId).then((response) => {
      setGroups(response.data)
      setLoading(false)
    })
  }, [profileId, show, setLoading, setGroups])


  const groupOptions = useMemo(() => {
    if (loading) return []

    return groups.map(group => ({
      id: group.id,
      label: group.name,
    }))
  }, [groups, loading])

  const handleChange = useCallback((_event, newValue) => {
    if (!newValue) {
      setSelectedGroupIds([])
      return
    }

    setSelectedGroupIds(newValue.map(group => group.id))
  }
  , [setSelectedGroupIds])


  const handleCommit = useCallback(() => {
    setLoading(true)
    profilesClient.createGroupAssignment(profileId, selectedGroupIds).then(() => {
      setSelectedGroupIds([])
      onSuccess()
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }, [profileId, selectedGroupIds, setLoading, onClose])

  if (!show) return null;

  return (
    <MuiModal
      titleText="Assign Groups"
      buttonText="Assign"
      onClose={onClose}
      onCommit={handleCommit}
    >
      <Grid
          item
          xs={12}
          container
          alignItems="center"
          spacing={2}
          sx={{ minHeight: "120px" }}
        >
          <Grid item xs={2}>
            <InputLabel id="group-select-label">Groups</InputLabel>
          </Grid>
          <Grid item xs={9}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="group-select"
                aria-labelledby="group-select-label"
                options={groupOptions}
                getOptionLabel={option => option.label}
                disableCloseOnSelect
                loading={loading}
                onChange={handleChange}
                disabled={loading}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select groups"
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
    </MuiModal>
  );
}